<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    <div
      :id="`${assetId}_popover_incidents`"
    >
      {{ openTickets }}
      <b-popover
        v-if="openTickets > 0"
        custom-class="wide-popover"
        :target="`${assetId}_popover_incidents`"
        :disabled="openTickets === 0"
        triggers="hover"
        @show="onShow"
      >
        <template #title>
          Open Tickets
        </template>

        <b-overlay :show="isLoading">
          <p v-if="isLoading">
            Loading...
          </p>
          <ul
            v-if="!isLoading"
          >
            <li
              v-for="incident in incidents"
              :key="incident.id"
            >
              <BLink
                :href="`/ticket/${incident.id}`"
                target="_blank"
              >
                {{ incident.title }}
              </BLink>
            </li>
          </ul>
        </b-overlay>
      </b-popover>
    </div>
  </td>
</template>

<script>

import { BPopover, BOverlay, BLink } from 'bootstrap-vue'

import IncidentService from '@/service/incident.service'

export default {
  components: {
    BPopover,
    BOverlay,
    BLink,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    openTickets: {
      type: Number,
      default: 0,
    },
    tenantId: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
      dataLoaded: false,
      incidents: [],
    }
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
  },
  watch: {
    assetId() {
      /// cell stays the same after reordering grid. So we need to empty alerts cuz it could hold information about the data inside the same cell before reordering
      this.incidents = []
      this.dataLoaded = false // force reloading data for next time
    },
  },
  methods: {
    onShow() {
      if (this.dataLoaded === false) {
        this.isLoading = true

        IncidentService.getAllListAsync({ assetId: this.assetId, status: 0 }, { disableTenantFilter: true })
          .then(result => {
            this.incidents = result
          })
          .finally(() => {
            this.isLoading = false
            this.dataLoaded = true
          })
      }
    },
  },
}
</script>
